<template>
  <div class="g-layout-default">
    <the-header />
    <the-navigation />
    <nuxt />
    <the-footer />
  </div>
</template>

<script>
import TheHeader from '@/components/shared/header/TheHeader.vue'
import TheNavigation from '~/components/shared/navigation/TheNavigation.vue'
import TheFooter from '~/components/shared/footer/TheFooter.vue'
export default {
  name: 'LayoutStandard',
  components: {
    'the-header': TheHeader,
    'the-navigation': TheNavigation,
    'the-footer': TheFooter
  }
}
</script>
