<template>
  <footer id="g-layout-footer" class="g-flexcenter">
    <base-icon id="b-footer-logo" name="niraslogo" />
    <div id="b-footer-version">
      <span>Version {{ ver }}</span>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'TheFooter',
  data() {
    return {
      ver: process.env.packageVersion
    }
  }
}
</script>

<style lang="scss" scoped>
  #b-footer-logo {
    width: 80px;
    height: 30px;
    margin-left: 30px;
  }

  #b-footer-version {
    margin-left: auto;
    margin-right: 50px;
  }
</style>
