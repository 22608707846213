import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _77019dd1 = () => interopDefault(import('..\\pages\\administration.vue' /* webpackChunkName: "pages_administration" */))
const _6b430862 = () => interopDefault(import('..\\pages\\cases\\index.vue' /* webpackChunkName: "pages_cases_index" */))
const _058a8d3d = () => interopDefault(import('..\\pages\\contact.vue' /* webpackChunkName: "pages_contact" */))
const _993f6e18 = () => interopDefault(import('..\\pages\\help.vue' /* webpackChunkName: "pages_help" */))
const _f724d554 = () => interopDefault(import('..\\pages\\overview\\index.vue' /* webpackChunkName: "pages_overview_index" */))
const _f7187782 = () => interopDefault(import('..\\pages\\reductionkey.vue' /* webpackChunkName: "pages_reductionkey" */))
const _4624de60 = () => interopDefault(import('..\\pages\\cases\\case.vue' /* webpackChunkName: "pages_cases_case" */))
const _16239e66 = () => interopDefault(import('..\\pages\\cases\\create\\index.vue' /* webpackChunkName: "pages_cases_create_index" */))
const _f772818c = () => interopDefault(import('..\\pages\\overview\\createBorehole\\index.vue' /* webpackChunkName: "pages_overview_createBorehole_index" */))
const _147678e4 = () => interopDefault(import('..\\pages\\overview\\modellingQueue\\index.vue' /* webpackChunkName: "pages_overview_modellingQueue_index" */))
const _260cbb18 = () => interopDefault(import('..\\pages\\cases\\_xid.vue' /* webpackChunkName: "pages_cases__xid" */))
const _3dd2c62f = () => interopDefault(import('..\\pages\\index.vue' /* webpackChunkName: "pages_index" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/administration",
    component: _77019dd1,
    name: "administration"
  }, {
    path: "/cases",
    component: _6b430862,
    name: "cases"
  }, {
    path: "/contact",
    component: _058a8d3d,
    name: "contact"
  }, {
    path: "/help",
    component: _993f6e18,
    name: "help"
  }, {
    path: "/overview",
    component: _f724d554,
    name: "overview"
  }, {
    path: "/reductionkey",
    component: _f7187782,
    name: "reductionkey"
  }, {
    path: "/cases/case",
    component: _4624de60,
    name: "cases-case"
  }, {
    path: "/cases/create",
    component: _16239e66,
    name: "cases-create"
  }, {
    path: "/overview/createBorehole",
    component: _f772818c,
    name: "overview-createBorehole"
  }, {
    path: "/overview/modellingQueue",
    component: _147678e4,
    name: "overview-modellingQueue"
  }, {
    path: "/cases/:xid",
    component: _260cbb18,
    name: "cases-xid"
  }, {
    path: "/",
    component: _3dd2c62f,
    name: "index"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
