<template>
  <transition name="g-fade">
    <div class="g-modal" v-if="config._show">
      <div class="g-modal-overlay" :class="{ 'g-modal-right' : config.right }">
        <div class="g-modal-dialog">
          <div v-if="config.header || $slots['header']" class="g-modal-header">
            <slot name="header">
              <base-icon v-if="config.headerIcon" :name="config.headerIcon" class="g-modal-header-icon" />
              <div>
                {{ config.header }}
              </div>
            </slot>
          </div>
          <div v-if="config.content || $slots['content']" class="g-modal-content">
            <slot name="content">
              <div v-html="config.content"/>
            </slot>
          </div>
          <div v-if="config.accept || config.reject || config.buttons.length > 0" class="g-modal-buttons">
            <template v-for="(button, idx) in config.buttons">
              <base-button :text="button.text" :mode="button.defaultButton ? 'solid' : 'hollow-dark'" :key="idx" :auto-focus="button.defaultButton" @click="buttonClicked(button)" />
            </template>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { BaseModalConfig } from './BaseModal.js'

export default {
  name: 'Modal',
  props: {
    config: {
      type: Object,
      default: new BaseModalConfig({})  
    }
  },
  methods: {
    buttonClicked(button) {
      if (typeof button.action === 'string') {
        this.$emit(button.action)
      } else {
        button.action()
      }
      if (button.close) {
        this.config.close()
      }
    }
  }
}
</script>

<style lang="scss">
  .g-modal {
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 999;
    background: rgba(0,0,0,0.2);

    .g-modal-overlay {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      &.g-modal-right {
        justify-content: flex-end;
      }

      .g-modal-dialog {
        min-width: 2px;
        min-height: 2px;
        background-color: white;
        border-radius: 5px;
        box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);

        .g-modal-header {
          background-color: $color-top-bar;
          border-radius: 5px 5px 0 0;
          // padding: 3px;
          color: #ffffff;
          min-height: 40px;
          display: flex;
          justify-content: center;
          align-items: center;
          
          .g-modal-header-icon {
            margin-right: 5px;
            width: 20px;
            height: 20px;
            
            svg {
              fill: white;
            }
          }
        }

        .g-modal-content {
          // padding: 3px;
          border-left: 2px solid darkgrey;
          border-right: 2px solid darkgrey;
        }

        .g-modal-buttons {
          display: flex;
          justify-content: center;
          // justify-content: flex-end;
          align-items: center;
          // padding: 3px;
          background-color: $color-top-bar;
          border-radius: 0 0 5px 5px;
          height: 40px;
                    
          > *:not(:last-child) {
            margin-right: 15px;
          }
        }
      }
    }
  }
</style>
