<template>
  <div>
    <div class="g-radio-group" :class="{'g-radio-group-vertical': vertical}">
      <label v-for="(radio, index) in radios" :key="index" class="radio g-radio-item">
        <input type="radio" :checked="radio.value === value" :name="name" @input="$emit('input', radio.value)">
        <span v-if="radio.text" class="g-radio-item-text">{{ radio.text }}</span>
      </label>
    </div>
    <div v-if="help" class="help is-danger">
      {{ help }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseInputRadio',
  props: {
    radios: {
      type: Array,
      required: true
    },
    value: {
      type: undefined,
      required: false,
      default: null
    },
    vertical: {
      type: Boolean,
      required: false
    },
    help: {
      type: String,
      required: false,
      default: null
    }
  },
  data () {
    return {
      name: Math.random().toString(36).slice(2)
    }
  }
}
</script>

<style lang="scss" scoped>
.g-radio-group {
  &.g-radio-group-vertical {
    display: flex;
    flex-direction: row;
  }

  .g-radio-item {
    display: flex;
    align-items: center;
    margin-left: 0;
    margin-bottom: 8px;
    padding-right: 3em;

    .g-radio-item-text {
      flex: 1;
      padding-left: 5px;
    }
  }
}
</style>
