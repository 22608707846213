const state = {
  createdBy: null,
  createdAt: null,
  updatedAt: null,
  caseName: null,
  caseNumber: null,
  caseRemark: null,
  caseXid: null
}

const getters = {
  getOpenCaseInfo: (state) => {
    return {
      createdBy: state.createdBy,
      createdAt: state.createdAt,
      updatedAt: state.updatedAt,
      caseName: state.caseName,
      caseNumber: state.caseNumber,
      caseRemark: state.caseRemark,
      caseXid: state.caseXid
    }
  }
}

const mutations = {
  setOpenCaseInfo: (state, payload) => {
    state.createdBy = payload.createdBy
    state.createdAt = payload.createdAt
    state.updatedAt = payload.updatedAt
    state.caseName = payload.caseName
    state.caseNumber = payload.caseNumber
    state.caseRemark = payload.caseRemark
    state.caseXid = payload.xid
  }
}

const actions = {
  setOpenCaseInfo: ({ commit }, data) => {
    commit('setOpenCaseInfo', data)
  },
  resetOpenCaseInfo: ({ commit }) => {
    state.createdBy = null
    state.createdAt = null
    state.updatedAt = null
    state.caseName = null
    state.caseNumber = null
    state.caseRemark = null
    state.caseXid = null
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
