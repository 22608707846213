<template>
  <div class="field g-input-field" :style="{'width': widthPercentage + '%'}">
    <label class="label" :class="{ 'is-small' : smallTitle === true }">
      {{ title }}
      <span v-if="required" class="g-input-required">*</span>
    </label>
    <div class="field" :class="{ 'has-addons': lockedValue }">
      <p v-if="lockedValue" class="control g-staticWidth">
        <a class="button is-static b-staticWidth-button">
          {{ lockedValue }}
        </a>
      </p>
      <div class="control b-input" :class="{ 'lockedWidth' : lockedValue }">
        <input class="input" :class="{ 'is-danger' : error}" :type="type" :autocomplete="noAutoComplete()" :id="id" :placeholder="placeholder || title" :readonly="readonly" :value="value" :disabled="disabled" :autofocus="autofocus" @input="$emit('input', $event.target.value)">
      </div>
      <p v-if="help" class="help is-danger">
        {{ help }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseInputText',
  props: {
    title: {
      type: String,
      required: false,
      default: null
    },
    type: {
      type: String,
      required: false,
      default: 'text'
    },
    required: {
      type: Boolean,
      required: false
    },
    readonly: {
      type: Boolean,
      required: false
    },
    smallTitle: {
      type: Boolean,
      required: false
    },
    lockedValue: {
      type: [String, Number],
      required: false,
      default: null
    },
    id: {
      type: String,
      required: false,
      default: null
    },
    value: {
      type: String,
      required: false,
      default: null
    },
    placeholder: {
      type: String,
      required: false,
      default: null
    },
    disabled: {
      type: Boolean,
      required: false
    },
    autofocus: {
      type: Boolean,
      required: false
    },
    help: {
      type: String,
      required: false,
      default: null
    },
    widthPercentage: {
      type: [Number, String],
      required: false,
      default: 100
    },
    error: {
      type: Boolean, 
      required: false,
      default: false
    }
  },
  methods: {
    updateInput() {
      this.$emit('inputValue', {id: this.id, value: this.value})
    },
    noAutoComplete() {
      if (this.type === 'password' || this.type === 'tel') {
        return 'new-password'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.g-input-field {
  // margin-bottom: 1rem;

  .g-input-required {
    color: $color-input-required;
  }

  .b-input {
    // width: 50%;

    &.lockedWidth {
      // width: 43%;
      width: 100%;
    }
  }
  .b-staticWidth {
    width: 7%;

    .b-staticWidth-button {
      width: 100%;
    }
  }
}

.label {
  margin-bottom: 0px;
}
</style>
