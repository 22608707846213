<template>
  <label class="checkbox g-checkbox">
    <input type="checkbox" :checked="value" :disabled="disabled" @change="$emit('input', $event.target.checked)">
    <span v-if="title" class="g-checkbox-text">{{ title }}</span>
  </label>
</template>

<script>
export default {
  name: 'BaseInputCheckbox',
  props: {
    title: {
      type: String,
      required: false,
      default: null
    },
    value: {
      type: Boolean,
      required: false,
      default: false
    },
    disabled: {
      type: Boolean,
      required: false
    }
  }
}
</script>

<style lang="scss" scoped>
.g-checkbox {
  display: flex;
  align-items: center;
  margin-left: 0;
  // margin-bottom: 8px;
  padding-right: 5em;

  .g-checkbox-text {
    flex: 1;
    padding-left: 5px;
  }
}
</style>
