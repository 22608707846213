import vuex from 'vuex'
import global from './global.js'
import openCase from './case.js'

const store = new vuex.Store({
  modules: {
    global,
    openCase
  }
})

export default function () {
  return store
}
