<template>
  <nav id="g-layout-nav">
    <!-- <a v-if="currentPage === 'overview'" class="b-nav-overview-selection g-flexcenter">
      <div class="g-flexcenter g-flexcolumn">
        <base-icon class="b-nav-overview-selection-icon" name="borehole" />
        <span class="b-nav-overview-selection-text">{{ $t('navigation.selection_method_one_text') }}</span>
      </div>
      <base-icon class="b-nav-overview-selection-arrow" name="arrowright" />
    </a> -->
    <!-- <div v-if="currentPage === 'overview'" id="b-nav-borehole" class="g-flexcenter g-flexcolumn">
       <h5 class="navigation_overview">
        Tilladelser
      </h5>
      <div class="navigation_overview_permission_container">
        <div class="navigation_overview_permission g-flexcenter g-flexcolumn">
          <span class="navigation_overview_permission_amount">1</span>
          <span class="navigation_overview_permission_text">udløber i dag</span>
        </div>
        <div class="navigation_overview_permission g-flexcenter g-flexcolumn">
          <span class="navigation_overview_permission_amount">2</span>
          <span class="navigation_overview_permission_text">udløber i juni</span>
        </div>
        <base-icon class="larger-svg navigation_type_drillicon" name="chart_column"></icon>
      </div>
    </div> -->   
    <div v-if="currentPage === 'openCase' && getOpenCaseInfo.createdBy !== null" class="b-nav-openCase">
      <div class="g-flexcolumn">
        <span class="b-nav-caseName">{{ getOpenCaseInfo.caseName }}</span>
        <span class="b-nav-caseNo">{{ getOpenCaseInfo.caseNumber }}</span>
      </div>
      <div class="b-openCase-container">
        <span class="b-openCase-user">{{ getOpenCaseInfo.createdBy }}</span>
        <div class="b-openCase-text g-flexcolumn">
          <span class="b-openCase-title"> {{ $t('case.existingCase.navigation.created_text') }}</span>
          <span class="b-openCase-subtitle">{{ getOpenCaseInfo.createdAt }}</span>
        </div>
        <div class="b-openCase-text g-flexcolumn">
          <span class="b-openCase-title"> {{ $t('case.existingCase.navigation.updated_text') }}</span>
          <span class="b-openCase-subtitle">{{ getOpenCaseInfo.updatedAt }}</span>
        </div>
      </div>
    </div>
    <div v-if="currentPage === 'overview' || currentPage === 'cases'" class="b-space" />
    <div class="b-nav-buttons">
      <div v-if="currentPage === 'overview'">
        <nuxt-link to="overview/createBorehole"><base-button :mode="'solid'" :icon="'plusCircle'" :text="$t('boreholes.create_button')" /></nuxt-link>
        <nuxt-link to="overview/modellingQueue"><base-button :mode="'solid'" :icon="'queue'" :text="$t('boreholes.modelling_button')" /></nuxt-link>
      </div>
      <div v-if="currentPage === 'cases'">
        <nuxt-link to="/cases/create"><base-button :mode="'solid'" :icon="'plusCircle'" :text="$t('cases.create_button')" /></nuxt-link>
      </div>
    </div>
  </nav>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  name: 'TheNavigation',
  data() {
    return {
      currentPage: null
    }
  },
  computed: {
    ...mapGetters({
      getOpenCaseInfo: 'getOpenCaseInfo'
    })  
  },
  watch: {
    $route(to, from) {
      this.pageSetup(to)
    },
    getOpenCaseInfo(val, oldVal) {
      if (val.caseName) {
        this.currentPage = 'openCase'
      } 
    }
  },
  created() {
    this.pageSetup(this.$route)
  },
  methods: {
    ...mapActions({
      resetOpenCaseInfo: 'resetOpenCaseInfo'
    }),
    pageSetup(page) {
      if (!page.params.xid) {
        this.currentPage = ''
        this.resetOpenCaseInfo()
      } 
      if (page.name === 'overview') {
        this.currentPage = 'overview'
      } else if (page.name === 'cases') {
        this.currentPage = 'cases'
      } else {
        this.currentPage = ''
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  #g-layout-nav {
    display: flex;
  }
  .b-nav-overview-selection {
    width: 130px;
    height: 100%;
    padding-left: 10px;

    &:hover {
      background-color: #ECECEC;
    }
  }

  .b-nav-overview-selection-text {
    color: $color-font-secondary;
    font-size: 1.1em;
    margin-top: 5px;
  }

  .b-nav-overview-selection-icon {
    width: 25px;
    height: 25px;
    fill: #4b515a;
  }

  .b-nav-overview-selection-arrow {
    width: 15px;
    height: 15px;
    fill: #dedede;
    margin-left: 10px;
  }

  .b-nav-buttons {
    display: flex;
    align-items: center;
    margin-right: 1em;
    
    .b-button {
      height: 50px;
      margin-right: 1.5em;
    }
  }

  //---------- BOREHOLE ----------//
  #b-nav-borehole {
  }

  .b-space {
    flex: 1;
  }

  // .navigation_overview_permission_container {
  //   display: flex;
  //   flex-direction: row;
  // }
  // .navigation_overview_container {
  //   margin-left: 40px;
  // }

  // .navigation_overview_permission {
  //   margin-right: 30px;
  // }
  // .navigation_overview_permission_amount {
  //   font-size: 1.5em;
  // }

  // .navigation_overview_permission_text {
  //   font-size: 0.8em;
  // }

  //---------- OPEN CASE ----------//
  .b-nav-openCase {
    display: flex;
    align-items: center;
    margin-left: 30px;

    .b-nav-caseName {
      font-size: 1.3em;
      font-weight: bold;
    }

    .b-nav-caseNo {

    }

    .b-openCase-container {
      display: flex;
      margin-left: 70px;

      .b-openCase-user {
        background-color: $color-top-bar;
        border-radius: 50%;
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 40px;
        margin: 5px -15px 5px 0;
        font-size: 0.7em;
      }
      .b-openCase-text {
        padding-right: 25px;
        margin-left: 25px;
        border-right: 1px solid #DEDEDE;
        .b-openCase-title {
          font-size: 1.2em;
        }
        .b-openCase-subtitle {
          font-size: 0.9em;
        }
      }
    }
  }

</style>
