export class BaseModalButton {
  constructor(def) {
    this.name = def.name || ''
    this.text = def.text || ''
    this.action = def.action
    this.defaultButton = def.defaultButton === true
    this.close = def.hasOwnProperty('close') ? def.close : true
  }
}

export class BaseModalConfig {
  constructor(def) {
    this._show = false
    this.right = def.right === true
    this.buttons = []

    if (def.accept) {
      this.buttons.push(new BaseModalButton({
        name: 'accept',
        text: def.accept,
        action: def.acceptAction || 'accept',
        defaultButton: true
      }))
    }

    if (def.reject) {
      this.buttons.push(new BaseModalButton({
        name: 'reject',
        text: def.reject,
        action: def.rejectAction || 'reject'
      }))
    }

    this.autoClose = def.autoClose === true

    this.header = def.hasOwnProperty('header') ? def.header : false
    this.headerIcon = def.hasOwnProperty('headerIcon') ? def.headerIcon : false
    this.content = def.hasOwnProperty('content') ? def.content : false
  }

  addButton(button) {
    this.buttons.push(button)
  }
  
  open() {
    this._show = true
  }
  
  close() {
    this._show = false
  }
}
