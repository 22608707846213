<template>
  <button v-if="kind === 'standard'" type="button" class="b-button b-button-standard" :title="title" :class="getClass()" @click="$emit('click', $event)" :autoFocus="autoFocus" :disabled="disabled">
    <div class="b-button-content">
      <base-icon v-if="icon" :name="icon" class="b-button-icon" />
      <div v-if="text" class="b-button-text">
        {{ text }}
      </div>
    </div>
  </button>
  <!-- eslint-disable-next-line vue/valid-template-root -->
  <button v-else type="button" class="b-button b-button-map" :title="title" @click="$emit('click', $event)">
    <div class="b-button-content">
      <div v-if="text" class="b-button-text">
        {{ text }}
      </div>
      <base-icon :name="'arrowright'" class="b-button-icon" />
    </div>
  </button>    
</template>

<script>
export default {
  name: 'BaseButton',
  props: {
    kind: {
      type: String,
      default: 'standard',
      validator: (value) => {
        return ['standard', 'map'].includes(value)
      }
    },
    mode: {
      type: String,
      default: 'hollow',
      validator: (value) => {
        return ['solid', 'hollow', 'hollow-dark'].includes(value)
      }
    },
    text: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: ''
    },
    autoFocus: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    }
  },
  methods: {
    getClass() {
      let result = 'b-button-' + this.mode
      if (!this.text) {
        result += ' b-button-icononly'
      }
      return result
    }
  }
}
</script>

<style lang="scss">
  .b-button-standard {
    background: none;
    min-width: 100px;
    border: 1px solid $color-primary;
    color: $color-primary;
    border-radius: 5px;
    cursor: pointer;
    padding-left: 10px;
    padding-right: 10px;
    min-height: 30px;

    &.b-button-icononly {
      min-width: 0;
      height: 30px;

      .b-button-icon {
        margin-right: 0;
      }
    }
    
    .b-button-content {
      display: flex;    
      justify-content: center;
      align-items: center;    
    }    
        
    .b-button-icon {
      width: 20px;
      height: 20px;
      margin-right: 5px;

      svg {
        fill: $color-primary;
      }
    }

    .b-button-text {
      font-size: 1rem;
    }

    &.b-button-solid {
      background-color: $color-primary;
      color: white;

      svg {
        fill: white;
      }
    }
    
    &.b-button-hollow-dark {
      color: white;

      svg {
        fill: white;
      }
    }
    
    &:hover {
      background-color: $color-primary-hot;
      border-color: $color-primary-hot;
      color: white;
      
      svg {
        fill: white;        
      }
    }
    &:disabled {
      border-color: gray;
      opacity: 0.5;
      color: gray;
      cursor: not-allowed;

      // &:hover {
      //   background-color: white;
      //   color: gray;
      //   border-color: gray;

      //   svg {
      //     fill: gray;
      //   }
      // }
    }  
  }
  
  .b-button-map {
    min-width: 170px;
    background-color: $color-primary;
    border: none;
    border-radius: 20px;
    min-height: 40px;
    color: white;
    cursor: pointer;
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    justify-content: flex-end;
    margin-right: 20px;
    margin-bottom: 10px;
    box-shadow: 3px 5px 5px 1px #888888;
        
    .b-button-content {
      display: flex;    
      justify-content: center;
      align-items: center;
    
      .b-button-icon {
        width: 20px;
        height: 20px;
        margin-left: 5px;
        
        svg {
          fill: white;
        }
      }
      
      .b-button-text {
        font-size: 0.8rem;
        margin-right: 20px;
        text-transform: uppercase;
        font-family: 'Roboto', 'Avenir', Helvetica, Arial, sans-serif;
        font-weight: bold;
      }
    }    
    
    &:hover {
      background-color: $color-primary-hot;      
    }
  }
</style>
