import { NMapStyle, NMapStyleCircle } from 'ncomponents-map'
import Vue from 'vue'
import numeral from 'numeral'
import moment from 'moment'
import BaseIcon from '@/components/base/icon/BaseIcon.vue'
import BaseButton from '@/components/base/button/BaseButton.vue'

Vue.component('base-icon', BaseIcon)
Vue.component('base-button', BaseButton)

Vue.prototype.$floatToString = (num) => {
  if (num < 1 && num > -1) {
    return numeral(parseFloat(num.toPrecision(2))).format('0,0[.][00000000]')
  } else if (num < 10 && num > -10) {
    return numeral(num).format('0,0[.][00]')
  } else if (num < 1000 && num > -1000) {
    return numeral(num).format('0,0[.][0]')
  } else {
    return numeral(num).format('0,0')
  }
}

Vue.prototype.$formatDate = (date) => {
  return moment(date).format('L - LT')
}

Vue.prototype.$saveDownloadData = (fileData, fileName, mimeType) => {
  const blob = new Blob([fileData], { type: mimeType })

  const link = document.createElement('a')
  link.href = window.URL.createObjectURL(blob, fileName)
  link.download = fileName
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

Vue.prototype.$getMunicipalityMapListStyle = () => {
  return new NMapStyle({
    fillColor: '#ffffff',
    fillOpacity: 0,
    strokeColor: '#000000',
    strokeWidth: 2
  })
}

Vue.prototype.$getBoreholeStyle = (use, selected) => {
  const colors = {
    V: '#7cfff7',
    VA: '#e5b0e4',
    VD: '#0d1fed',
    VH: '#32e529',
    VI: '#c92909',
    VM: '#bcfb00',
    VP: '#7CFFF7',
    VR: '#f3f3f3',
    VS: '#4e4e12',
    VV: '#20b2fb',
    Otherwise: '#bbbbbb'
  }

  return new NMapStyleCircle({
    fillColor: colors[use] || colors.Otherwise,
    radius: 5,
    strokeColor: selected ? '#FF0000' : '#777777'
  })
}

Vue.prototype.$getBoreholeHotStyle = (use, selected) => {
  const colors = {
    V: '#7cfff7',
    VA: '#e5b0e4',
    VD: '#0d1fed',
    VH: '#32e529',
    VI: '#c92909',
    VM: '#bcfb00',
    VP: '#7CFFF7',
    VR: '#f3f3f3',
    VS: '#4e4e12',
    VV: '#20b2fb',
    Otherwise: '#bbbbbb'
  }

  return new NMapStyleCircle({
    fillColor: colors[use] || colors.Otherwise,
    radius: 10,
    strokeColor: selected ? '#FF0000' : '#777777'
  })
}

Vue.prototype.$getBoreholeSelectedStyle = (use, selected) => {
  const colors = {
    V: '#7cfff7',
    VA: '#e5b0e4',
    VD: '#0d1fed',
    VH: '#32e529',
    VI: '#c92909',
    VM: '#bcfb00',
    VP: '#7CFFF7',
    VR: '#f3f3f3',
    VS: '#4e4e12',
    VV: '#20b2fb',
    Otherwise: '#bbbbbb'
  }

  return new NMapStyleCircle({
    fillColor: colors[use] || colors.Otherwise,
    radius: 10,
    strokeColor: selected ? '#FF0000' : '#777777'
  })
}

Vue.prototype.$getBoreholeFadedStyle = () => {
  return new NMapStyleCircle({
    fillColor: '#ffffff',
    fillOpacity: 0,
    radius: 5,
    strokeColor: '#777777',
    strokeOpacity: 0.3
  })
}

Vue.prototype.$getBoreholeFadedHotStyle = () => {
  return new NMapStyleCircle({
    fillColor: '#ffffff',
    fillOpacity: 0,
    radius: 10,
    strokeColor: '#777777',
    strokeOpacity: 0.3
  })
}

Vue.prototype.$getBoreholeOtherStyle = () => {
  return new NMapStyleCircle({
    fillColor: '#777777',
    radius: 5,
    strokeColor: '#0000ff',
    strokeOpacity: 0.3
  })
}

Vue.prototype.$getWaterstreamStyle = () => {
  return new NMapStyle({
    strokeColor: '#0000ff',
    strokeWidth: 1
  })
}

Vue.prototype.$getLoweringStyle = (value, isAquifer) => {
  let result = null
  let opacity = 0
  
  if (isAquifer) {
    result = new NMapStyle({
      fillColor: '#800080',
      strokeColor: '#ff0000'
    })    
  } else {
    result = new NMapStyle({
      fillColor: '#00ffff',
      strokeColor: '#0000ff'
    })
    opacity = 0.1
  }
  
  if (value >= 0.005) { opacity = 0.1 }
  if (value > 0.02) { opacity = 0.2 }
  if (value > 0.05) { opacity = 0.3 }
  if (value > 0.1) { opacity = 0.4 }
  if (value > 0.2) { opacity = 0.5 }
  if (value > 0.5) { opacity = 0.6 }
  if (value > 1) { opacity = 0.7 }
  if (value > 2) { opacity = 0.8 }
  if (value > 5) { opacity = 0.9 }
  if (value > 10) { opacity = 1 }
  
  result.fillOpacity = opacity;
  result.strokeOpacity = opacity;

  return result
}

Vue.prototype.$getLoweringHotStyle = (value, isAquifer) => {
  let result = null
  let opacity = 0
  
  if (isAquifer) {
    result = new NMapStyle({
      fillColor: '#800080',
      strokeColor: '#000000',
      strokeWidth: 2
    })    
  } else {
    result = new NMapStyle({
      fillColor: '#00ffff',
      strokeColor: '#000000',
      strokeWidth: 2
    })
    opacity = 0.1
  }
  
  if (value >= 0.005) { opacity = 0.1 }
  if (value > 0.02) { opacity = 0.2 }
  if (value > 0.05) { opacity = 0.3 }
  if (value > 0.1) { opacity = 0.4 }
  if (value > 0.2) { opacity = 0.5 }
  if (value > 0.5) { opacity = 0.6 }
  if (value > 1) { opacity = 0.7 }
  if (value > 2) { opacity = 0.8 }
  if (value > 5) { opacity = 0.9 }
  if (value > 10) { opacity = 1 }
  
  result.fillOpacity = opacity;
  result.strokeOpacity = 0.85;

  return result
}

Vue.prototype.$getSelectCatchmentStyle = () => {
  return new NMapStyle({
    fillColor: '#ffffff',
    fillOpacity: 0.2,
    strokeColor: '#333333',
    strokeWidth: 2
  })
}
Vue.prototype.$getSelectCatchmentHoverStyle = () => {
  return new NMapStyle({
    fillColor: '#808080',
    fillOpacity: 0.4
  })
}
Vue.prototype.$getSelectCatchmentSelectedStyle = () => {
  return new NMapStyle({
    fillColor: '#808080',
    fillOpacity: 0.4
  })
}

Vue.prototype.$getSelectHabitatStyle = (subtype) => {
  const subtypes = {
    Eng: '#D3FFBE',
    Hede: '#E8BEFF',
    Mose: '#CEA966',
    Overdrev: '#FFA900',
    Strandeng: '#BEFFE8',
    Sø: '#454F88',
    Habitatnatur: '#8A2BE2'
  }
  return new NMapStyle({
    fillColor: subtypes[subtype] || '#bbbbbb',
    strokeColor: '#333333',
    strokeWidth: 2
  })
}
Vue.prototype.$getSelectHabitatHoverStyle = () => {
  return new NMapStyle({
    fillColor: '#808080',
    fillOpacity: 0.4
  })
}
Vue.prototype.$getSelectHabitatSelectedStyle = () => {
  return new NMapStyle({
    fillColor: '#808080',
    fillOpacity: 0.4
  })
}

Vue.prototype.$getPopupContent = (popup) => {
  let content = ''
  content += '<div class="g-popup">';
  popup.forEach((row) => {
    content += '<div><span style="font-weight: bold;">' + row.text + ': ' + '</span>' + row.value + '</div>';
  })
  content += '</div>';

  return content
}
