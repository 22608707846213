import axios from 'axios'

const axiosinstance = axios.create({
  withCredentials: false,
  headers: { 'Content-Type': 'application/json' }  
})

fetch('/config.json').then(response => response.json()).then((response) => {
  axiosinstance.defaults.baseURL = response.apiUrl
})

export default {
  get(url, request) {
    return axiosinstance.get(url, { params: request, headers: { Authorization: `Bearer ${sessionStorage.getItem('jwt')}` } })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error))
  },
  post(url, request) {
    return axiosinstance.post(url, request, { headers: { Authorization: `Bearer ${sessionStorage.getItem('jwt')}` } })
      .then(response => Promise.resolve(response))
      .catch((error) => {
        return Promise.reject(error)
      })
  },
  getFile(url, request) {
    return axiosinstance.get(url, { responseType: 'arraybuffer', params: request, headers: { Authorization: `Bearer ${sessionStorage.getItem('jwt')}` } })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error))
  },
  putFile(url, formData) {
    return axiosinstance.post(url, formData, { headers: { 'Content-Type': 'multipart/form-data', Authorization: `Bearer ${sessionStorage.getItem('jwt')}` } })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error))    
  },
  config: axiosinstance.defaults
}
