<template>
  <header id="g-layout-header" class="b-header">
    <div class="b-header-logo">
      <base-icon class="b-header-icon-logo" name="bestfulllogo" />
    </div>
    <div class="b-header-menu g-flexcenter">
      <nuxt-link v-if="userInfo.success" to="/overview" class="g-flexcenter">{{ $t('header.overview') }}</nuxt-link>
      <nuxt-link v-if="userInfo.success" to="/cases" class="g-flexcenter">{{ $t('header.cases') }}</nuxt-link>
      <nuxt-link v-if="userInfo.hasUserAdministrationAccess || userInfo.hasAdminUserAdministrationAccess || userInfo.hasMunicipalityListAdministrationAccess || userInfo.hasMunicipalityAdministrationAccess" to="/administration" class="g-flexcenter">{{ $t('header.admin') }}</nuxt-link>
      <nuxt-link v-if="userInfo.success" to="/help" class="g-flexcenter">{{ $t('header.help') }}</nuxt-link>
      <nuxt-link v-if="userInfo.success" to="/contact" class="g-flexcenter">{{ $t('header.contact') }}</nuxt-link>
      <nuxt-link v-if="userInfo.success" to="/reductionkey" class="g-flexcenter">{{ $t('header.reductionkey') }}</nuxt-link>
    </div>
    <div v-if="userInfo.success" class="b-header-user g-flexcenter">
      <div @click="toggleMenu()" class="b-header-user-link g-flexcenter">
        <base-icon class="b-header-icon-user" name="user" />
        <div class="b-header-user-text g-flexcolumn">
          <span class="b-header-user-name">{{ userInfo.user.name }} ({{ userInfo.user.initials }})</span>
          <span class="b-header-user-location">{{ municipality.name }}</span>
        </div>
      </div>
      
      <div v-if="showMenu" class="b-header-user-dropdown">
        <ul>
          <li class="b-header-user-dropdown-item">
            <a @click="openEditUserModal()" class="b-header-user-dropdown-item-link">{{ $t('header.profile') }}</a>
          </li>
          <li class="b-header-user-dropdown-item">
            <a @click="openSettingsModal()" class="b-header-user-dropdown-item-link">{{ $t('header.settings') }}</a>
          </li>
          <li class="b-header-user-dropdown-item">
            <nuxt-link to="/" class="b-header-user-dropdown-item-link">{{ $t('header.logout') }}</nuxt-link>
          </li>
        </ul>
      </div>
    </div>
    <base-modal :config="editUserModalConfig" @accept="saveEditUser()">
      <template v-slot:header>
        {{ $t('admin.users.edit_profile') }}
      </template>
      <template v-slot:content>
        <div class="b-modal-content">
          <base-input-text v-model="user.name" :title="$t('admin.users.header.column_one')" :required="true" :error="user.name === ''" />
          <base-input-text v-model="user.initials" :title="$t('admin.users.header.column_two')" :required="true" :error="user.initials === ''" />
          <base-input-text v-model="user.email" :title="$t('admin.users.header.column_three')" :required="true" :type="'email'" :error="user.email === ''" />
          <base-input-text v-model="user.phone" :title="$t('admin.users.header.column_four')" :type="'tel'" />
          <base-input-text v-model="passwordNew" :title="$t('admin.users.header.column_seven')" :type="'password'" :error="notSamePassword.error" :help="notSamePassword.help" />
          <base-input-text v-model="passwordRepeat" :title="$t('admin.users.header.column_eight')" :type="'password'" :error="notSamePassword.error" :help="notSamePassword.help" />
          <label class="label">{{ $t('admin.users.modal.groups.title') }} <span class="g-input-required">*</span></label>
          <base-input-radio-group :radios="inputRadioGroupGroups" v-model="user.groupId" :vertical="true" />
          <div class="b-model-content-right-checkbox">
            <base-input-checkbox v-model="user.newsletter" :title="$t('admin.users.modal.newsletter')" />
          </div>
        </div>
      </template>
    </base-modal>
    <base-modal :config="settingsModalConfig">
      <template v-slot:header>
        {{ $t('createBorehole.tabs.settings') }}
      </template>
      <template v-slot:content>
        <div class="b-modal-content">
          <!-- logo -->
          <div class="b-modal-content-item">
            <label class="label">{{ $t('header.municipality.logo.text') }} {{ municipality.name }}</label>
          </div>
          <!-- lowerings -->
          <div class="b-modal-content-item">
            <label class="label">{{ $t('header.municipality.lowerings.text') }} {{ municipality.name }}</label>
            <table class="table is-bordered">
              <thead>
                <tr>
                  <th colspan="2" class="b-table-borderless"></th>
                  <th colspan="2" class="b-table-borderless">{{ $t('admin.municipality.lowerings.table.header_one') }}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td colspan="2" class="b-table-borderless"></td>
                  <td class="b-table-center">{{ $t('admin.municipality.lowerings.table.header_three') }}</td>
                  <td class="b-table-center">{{ $t('admin.municipality.lowerings.table.header_four') }}</td>
                </tr>
                <tr>
                  <th rowspan="2" class="table-center-row b-table-borderless">{{ $t('admin.municipality.lowerings.table.header_two') }}</th>
                  <td>{{ $t('admin.municipality.lowerings.table.header_five') }}</td>
                  <td>{{ municipality.criticalLoweringMatrix.highParagraph3 }}</td>
                  <td>{{ municipality.criticalLoweringMatrix.moderateParagraph3 }}</td>
                </tr>
                <tr>
                  <td>{{ $t('admin.municipality.lowerings.table.header_six') }}</td>
                  <td>{{ municipality.criticalLoweringMatrix.highNatura2000 }}</td>
                  <td>{{ municipality.criticalLoweringMatrix.moderateNatura2000 }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <!-- naturetypes -->
          <div class="b-modal-content-item">
            <label class="label">{{ $t('header.municipality.nature_types.text') }}</label>
            <ul>
              <li>
                <base-icon v-if="municipality.selectedWetlandTypes.__Eng" class="b-listitem-icon" name="checkmark"></base-icon>
                <div v-else class="b-placeholder-box"></div>
                <span>{{ $t('admin.municipality.nature_types.item_one') }}</span>
              </li>
              <li>
                <base-icon v-if="municipality.selectedWetlandTypes.__Hede" class="b-listitem-icon" name="checkmark"></base-icon>
                <div v-else class="b-placeholder-box"></div>
                <span>{{ $t('admin.municipality.nature_types.item_two') }}</span>
              </li>
              <li>
                <base-icon v-if="municipality.selectedWetlandTypes.__Mose" class="b-listitem-icon" name="checkmark"></base-icon>
                <div v-else class="b-placeholder-box"></div>
                <span>{{ $t('admin.municipality.nature_types.item_three') }}</span>
              </li>
              <li>
                <base-icon v-if="municipality.selectedWetlandTypes.__Overdrev" class="b-listitem-icon" name="checkmark"></base-icon>
                <div v-else class="b-placeholder-box"></div>
                <span>{{ $t('admin.municipality.nature_types.item_four') }}</span>
              </li>
              <li>
                <base-icon v-if="municipality.selectedWetlandTypes.__Strandeng" class="b-listitem-icon" name="checkmark"></base-icon>
                <div v-else class="b-placeholder-box"></div>
                <span>{{ $t('admin.municipality.nature_types.item_five') }}</span>
              </li>
              <li>
                <base-icon v-if="municipality.selectedWetlandTypes.__Soe" class="b-listitem-icon" name="checkmark"></base-icon>
                <div v-else class="b-placeholder-box"></div>
                <span>{{ $t('admin.municipality.nature_types.item_six') }}</span>
              </li>
              <li>
                <base-icon v-if="municipality.selectedWetlandTypes.__Habitatnatur" class="b-listitem-icon" name="checkmark"></base-icon>
                <div v-else class="b-placeholder-box"></div>
                <span>{{ $t('admin.municipality.nature_types.item_seven') }}</span>
              </li>
            </ul>
          </div>
          <!-- reference -->
          <div class="b-modal-content-item">
            <label class="label">{{ $t('admin.municipality.reference.period_text') }}</label>
            <ul>
              <li>
                <base-icon v-if="municipality.referencePeriod.enabled" class="b-listitem-icon" name="checkmark"></base-icon>
                <div v-else class="b-placeholder-box"></div>
                <span>{{ $t('header.municipality.reference.checkbox') }}</span>
              </li>
            </ul>
            <div class="field b-admin-reference-align">
              <div class="control b-input">
                <p><b>{{ $t('header.municipality.reference.period') }}:</b></p>
                <p>{{ municipality.referencePeriod.fromYear }}</p>
                -
                <p>{{ municipality.referencePeriod.toYear }}</p>
              </div>
            </div>
          </div>
        </div>
      </template>
    </base-modal>
  </header>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import moment from 'moment'
import numeral from 'numeral'
import 'numeral/locales'
import { NMapUtils } from 'ncomponents-map'
import BaseModal from '@/components/base/modal/BaseModal.vue'
import { BaseModalConfig } from '@/components/base/modal/BaseModal.js'
import BaseInputText from '@/components/base/input/BaseInputText.vue'
import BaseInputCheckbox from '@/components/base/input/BaseInputCheckbox'
import BaseInputRadioGroup from '@/components/base/input/BaseInputRadioGroup'
import axios from '@/plugins/axios.js'

export default {
  name: 'TheHeader',
  components: {
    'base-modal': BaseModal,
    'base-input-text': BaseInputText,
    'base-input-checkbox': BaseInputCheckbox,
    'base-input-radio-group': BaseInputRadioGroup
  },
  data() {
    return {
      showMenu: false,
      user: {},
      passwordNew: '',
      passwordRepeat: '',
      notSamePassword: {
        error: false,
        help: ''
      },
      inputRadioGroupGroups: [],
      editUserModalConfig: new BaseModalConfig({
        reject: this.$t('admin.users.modal.reject'),
        accept: this.$t('admin.users.modal.edit')
      }),
      settingsModalConfig: new BaseModalConfig({
        accept: this.$t('case.error_close')
      })
    }
  },
  computed: {
    ...mapGetters({
      userInfo: 'getUserInfo',
      getLanguage: 'getLanguage',
      municipality: 'getMunicipality'
    })
  },
  created() {
    if (!this.userInfo) {
      if (this.$route.path !== '/help/' && this.$route.path !== '/contact') {
        this.$router.push({
          name: 'index'
        })
      } else {
        this.setUserInfo({
          hasAdminUserAdministrationAccess: false,
          hasMunicipalityAdministrationAccess: false,
          hasMunicipalityListAdministrationAccess: false,
          hasUserAdministrationAccess: false,
          lookups: {},
          municipalities: [],
          success: false,
          user: {}
        })
      }       
    }

    NMapUtils.registerProjection('EPSG:25832', '+proj=utm +zone=32 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs')
    moment.locale(this.getLanguage)
    if (this.getLanguage === 'da') {
      numeral.locale('da-dk')
    }

    const radioItemGroup1 = {
      value: 1,
      text: this.$t('admin.users.modal.groups.item_one')
    }
    const radioItemGroup2 = {
      value: 0,
      text: this.$t('admin.users.modal.groups.item_two')
    }
    const radioItemGroup3 = {
      value: 2,
      text: this.$t('admin.users.modal.groups.item_three')
    }
    const radioItemGroup4 = {
      value: 3,
      text: this.$t('admin.users.modal.groups.item_four')
    }
    this.inputRadioGroupGroups.push(radioItemGroup1, radioItemGroup2, radioItemGroup3, radioItemGroup4)
    
    this.editUserModalConfig.buttons.find(b => b.name === 'accept').close = false
  },
  methods: {
    ...mapActions({
      setUserInfo: 'setUserInfo'
    }),
    toggleMenu() {
      this.showMenu = !this.showMenu
    },
    openEditUserModal() {
      this.user = JSON.parse(JSON.stringify(this.userInfo.user))
      this.passwordNew = ''
      this.passwordRepeat = ''
      this.toggleMenu()
      this.editUserModalConfig.open()
    },
    openSettingsModal () {
      this.toggleMenu()
      this.settingsModalConfig.open()
    },
    async saveEditUser() {
      let passwordSend
      this.notSamePassword.error = false
      this.notSamePassword.help = ''
      let sendMailToUser = false
      if (this.passwordNew === '') {
        passwordSend = this.user.password
      } else {
        if (this.passwordNew === this.passwordRepeat) {
          passwordSend = this.passwordNew
        } else {
          this.notSamePassword.error = true
          this.notSamePassword.help = 'Kodeord er ikke ens'
          return
        }
        sendMailToUser = true
      }
      if (this.user.name === '' || this.user.initials === '' || this.user.email === '') {
        return
      }
      const updateUserData = {
        municipalityId: this.municipality.id,
        userId: this.user.id,
        email: this.user.email,
        initials: this.user.initials,
        name: this.user.name,
        phone: this.user.phone,
        groupId: this.user.groupId,
        userType: this.user.type,
        password: passwordSend,
        newsletter: this.user.newsletter,
        sendMailToUser: sendMailToUser
      }
      const response = await axios.post('/Administration/UpdateUser', updateUserData)
      if (response.data) {
        this.setUserInfo({data: this.user.name, target: 'name'})
        this.setUserInfo({data: this.user.initials, target: 'initials'})
        this.setUserInfo({data: this.user.email, target: 'email'})
        this.setUserInfo({data: this.user.phone, target: 'phone'})
        this.setUserInfo({data: passwordSend, target: 'password'})
        this.setUserInfo({data: this.user.groupId, target: 'groupId'})
        this.setUserInfo({data: this.user.newsletter, target: 'newsletter'})
        this.passwordNew = ''
        this.passwordRepeat = ''
        this.editUserModalConfig.close()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .b-header {
    display: flex;
    align-items: center;
  }

  .b-header-logo {
    display: flex;
    align-items: center;
    flex: 1 0 300px;
    margin-left: 30px;

    & .b-header-icon-logo {
      width: 260px;
      height: 27px;
    }
  }

  .b-header-menu {
    height: 100%;
    justify-content: space-evenly;
    flex: 1 0 50%;

    & a {
      color: $white;
      font-size: 1.1em;
      border-top: 5px solid transparent;
      border-bottom: 6px solid transparent;
      height: 100%;
      padding: 0px 30px;
    }

    & .nuxt-link-active {
      border-bottom: 6px solid $color-primary;
      font-weight: bold;
    }
  }

  .b-header-user {
    flex: 1 0 15%;
    justify-content: flex-end;
    position: relative;

    .b-header-user-link {
      padding-right: 50px;
      cursor: pointer;
    }

    @media screen and (max-width: 1600px) {
      .b-header-user-link {
        padding-right: 30px;
      }
    }

    .b-header-icon-user {
      width: 40px;
      height: 40px;
    }

    .b-header-user-text {
      color: $white;
      font-size: 0.9em;
      margin-left: 10px;
      line-height: 1.2em;
      font-weight: 300;
    }
  }

  .b-header-user-dropdown {
    position: absolute;
    background-color: $white;
    right: 0;
    top: 55px;
    width: 220px;
    z-index: 999;
    box-shadow: 3px 2px 5px 4px rgba(136, 136, 136, 0.5);

    .b-header-user-dropdown-item {
      display: flex;
      background-color: $white;
      text-align: center;
      border-bottom: 1px solid #e6e6e6;

      &:last-of-type {
        border-bottom: 0px solid #e6e6e6;
      }

      &:hover {
        background-color: #499AD4;

        a {
          color: $white;
        }
      }

      .b-header-user-dropdown-item-link {
        padding: 15px 35px;
        width: 100%;
        height: 100%;
        color: #4a4a4a;
      }
    }
  }

  .b-modal-content {
    padding: 15px 30px;

    .b-model-content-right-checkbox {
      margin-top: 10px;
    }
  }

  .b-modal-content-item {
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid #eee;

    ul li {
      display: flex;
      flex-direction: row;
    }

    .b-placeholder-box {
      width: 27px;
      height: 20px;
    }

    .b-listitem-icon {
      width: 20px;
      height: 20px;
      fill: $color-icon-primary;
      margin-right: 7px;
    }

    .b-admin-reference-align {
      .control {
        display: flex;
        align-items: center;

        p {
          margin-bottom: 0px;
          margin-right: 8px;

          &:last-of-type {
            margin-left: 8px;
          }

          input {
            font-size: 1rem;
          }
        }
      }

      .input {
        width: auto;

        &:first-of-type {
          margin-right: 10px;
        }

        &:last-of-type {
          margin-left: 10px;
        }
      }
    }
  }

  .table {
    .field {
      margin-bottom: 0px;
    }

    .table-center-row {
      vertical-align: middle;
    }

    .b-table-borderless {
      border: 0px;
    }

    .b-table-center {
      text-align: center;
    }
  }
  // ALC Temp
  .nuxt-link-disabled {
    color: black !important;
    font-weight: bold;
    pointer-events: none;
    cursor: not-allowed;
  }
</style>
