const state = {
  user: null,
  municipality: null,
  locale: 'da',
  criteria: {
    effect: 1,
    effectUnit: '%',
    choice: 1,
    waterdrop: 0.01,
    waterdropUnit: 'm',
    waterdropMaster: 0.01,
    waterdropMasterUnit: 'm'
  },
  municipalityMapList: null,
  jupiterUseList: null,
  windowSize: {},
  session: {
    newUserdefinedBoreholes: []
  }
}

const getters = {
  getUserInfo: (state) => {
    return state.user
  },
  getMunicipality: (state) => {
    return state.municipality
  },
  getLanguage: (state) => {
    return state.locale
  },
  getCaseCriteria: (state) => {
    return state.criteria
  },
  getMunicipalityMapList: (state) => {
    return state.municipalityMapList
  },
  getJupiterUseList: (state) => {
    return state.jupiterUseList
  },
  getWindowSize: (state) => {
    return state.windowSize
  },
  getSessionData: (state) => {
    return state.session
  }
}

const mutations = {
  setUserInfo: (state, payload) => {
    if (payload.target) {
      state.user.user[payload.target] = payload.data
    } else {
      state.user = payload
    }
  },
  setMunicipality: (state, payload) => {
    if (payload.target) {
      state.municipality[payload.target] = payload.data
    } else {
      state.municipality = payload
    }
  },
  setLanguage: (state, payload) => {
    state.locale = payload
  },
  setMunicipalityMapList: (state, payload) => {
    state.municipalityMapList = payload
  },
  setJupiterUseList: (state, payload) => {
    state.jupiterUseList = payload
  },
  setWindowSize: (state, payload) => {
    state.windowSize = payload
  },
  setUserDefinedBoreholes: (state, payload) => {
    state.session.newUserdefinedBoreholes.push(payload)
  }
}

const actions = {
  setUserInfo: ({ commit }, data) => {
    commit('setUserInfo', data)
  },
  setMunicipality: ({ commit }, data) => {
    commit('setMunicipality', data)
  },
  setLanguage: ({ commit }, data) => {
    commit('setLanguage', data)
  },
  setMunicipalityMapList: ({ commit }, data) => {
    commit('setMunicipalityMapList', data)
  },
  setJupiterUseList: ({ commit }, data) => {
    commit('setJupiterUseList', data)
  },
  setWindowSize: ({ commit }, data) => {
    commit('setWindowSize', data)
  },
  setUserDefinedBoreholes: ({ commit}, data) => {
    commit('setUserDefinedBoreholes', data)
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
